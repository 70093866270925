// import React, { useState } from "react";
// import "../CSS/SubscribeUser.css"; // Import your CSS file
// import { toast, ToastContainer } from "react-toastify";
// import Header from "../Components/Header";
// import Sidebar from "../Components/Sidebar";
// import { options } from "../Data/LoaderData";
// import { Unsubscription } from "../Data/Api";
// import Post from "../Request/Post";
// import Loader from "react-loader"; // Ensure this is correctly imported

// import { useNavigate } from "react-router-dom";

// const Unsubscribe = () => {
//   // const Loader = require("react-loader");

//   const navigate = useNavigate();
//   const [number, setNumber] = useState("");
//   const [divState, setDivState] = useState("none");
//   // const [packageType, setPackageType] = useState("daily");
//   // const [loading, setLoading] = useState(false);
//   const [loaderState, setLoaderState] = useState(true); // Default to true to show loader initially

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setLoaderState(false); // Show the loader
//     setDivState("block");
//     let request = {
//       msisdn: number,
//       serviceId: localStorage.getItem("serviceName"),
//     };

//     try {
//       // Call hittingBackendApi with the request data
//       hittingBackendApi(Unsubscription, request);
//       //   console.log("Response:", response);
//       // Handle successful response if needed
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(`Exception: ${error.message}`);
//     } finally {
//       setLoaderState(true); // Hide the loader
//       setDivState("none");
//     }
//   };

//   const hittingBackendApi = async (url, request) => {
//     try {
//       const response = await Post(url, request); // Assuming Post handles the API call

//       // // Check the Content-Type header
//       // const contentType = response.headers.get("content-type");

//       if (response.ok) {
//         // Handle non-JSON responses
//         const text = await response.text();
//         handleResponse(text);
//         setNumber("");
//       } else if (response.status === 404) {
//         setNumber("");
//         toast.error(`${response.statusText || "User is not Subscribed"}`);
//       } else if (response.status === 401) {
//         navigate("/");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       throw new Error(`Exception: ${error.message}`);
//     }
//   };

//   // Handle API response
//   const handleResponse = async (response) => {
//     try {
//       toast.success(response);
//         setDivState('none');
//     } catch (error) {
//       console.error("Failed to parse JSON:", error);
//       toast.error(`Failed to handle response: ${error.message}`);
//     }
//   };

//   // Loader component

//   return (
//     <>
    
//       <div className="loading-div" style={{ display: divState }}>
//         <Loader loaded={!loaderState} options={options} className="spinner" />
//       </div>
//       <ToastContainer />
//       <Header />
//       <Sidebar />
//       <div id="fourtTab" className="tabcontent">
//         <div className="subscribers-sec">
//           <div className="subscribers-home-l">
//             <span className="navigation-links">Home</span>
//             <span>/</span>
//             <span className="navigation-links">Unsubscribe User</span>
//           </div>
//         </div>
//         <div className="form-container">
//           <h1>Unsubscribe User</h1>
//           <form onSubmit={handleSubmit}>
//             <div>
//               <label htmlFor="number">Number:</label>
//               <input
//                 type="number"
//                 id="number"
//                 className="number-input"
//                 value={number}
//                 onChange={(e) => setNumber(e.target.value)}
//                 required
//               />
//             </div>
//             <button type="submit" className="submit-button">
//               Send
//             </button>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Unsubscribe;
import React, { useState } from "react";
import "../CSS/SubscribeUser.css"; // Import your CSS file
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { options } from "../Data/LoaderData";
import { Unsubscription } from "../Data/Api";
import Post from "../Request/Post";
import Loader from "react-loader"; // Ensure this is correctly imported
import { useNavigate } from "react-router-dom";

const Unsubscribe = () => {
  const navigate = useNavigate();
  const [number, setNumber] = useState("");
  const [loaderState, setLoaderState] = useState(false); // Default to false to hide loader initially

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoaderState(true); // Show the loader

    let request = {
      msisdn: number,
      serviceId: localStorage.getItem("serviceName"),
    };

    try {
      await hittingBackendApi(Unsubscription, request);
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Exception: ${error.message}`);
    } finally {
      setLoaderState(false); // Hide the loader
    }
  };

  const hittingBackendApi = async (url, request) => {
    try {
      const response = await Post(url, request); // Assuming Post handles the API call

      if (response.ok) {
        const text = await response.text();
        handleResponse(text);
        setNumber("");
      } else if (response.status === 404) {
        setNumber("");
        toast.error(`${response.statusText || "User is not Subscribed"}`);
      } else if (response.status === 401) {
        navigate("/");
      }
    } catch (error) {
      console.error("Error:", error);
      throw new Error(`Exception: ${error.message}`);
    }
  };

  const handleResponse = (response) => {
    toast.success(response);
  };

  return (
    <>
      {loaderState && (
        <div className="loading-div">
          <Loader loaded={!loaderState} options={options} className="spinner" />
        </div>
      )}
      <ToastContainer />
      <Header />
      <Sidebar />
      <div id="fourtTab" className="tabcontent">
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Unsubscribe User</span>
          </div>
        </div>
        <div className="form-container">
          <h1>Unsubscribe User</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="number">Number:</label>
              <input
                type="number"
                id="number"
                className="number-input"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="submit-button">
              Send
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Unsubscribe;
