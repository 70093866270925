import React, { useState } from "react";
import axios from "axios";
import "../CSS/SubscribeUser.css"; // Import your CSS file
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { options } from "../Data/LoaderData";
import { subscribeUser } from "../Data/Api";
import Post from "../Request/Post";

const SendDataForm = () => {
  const [number, setNumber] = useState("");
  const [packageType, setPackageType] = useState("daily");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setLoading(true); // Show the loader

    let request = {
      msisdn: number,
      pack: packageType,
      serviceId: localStorage.getItem("serviceName")
    };

    try {
      // Call hittingBackendApi with the request data
    hittingBackendApi(subscribeUser, request);
    //   console.log("Response:", response);
      // Handle successful response if needed
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Exception: ${error.message}`);
    } finally {
      setLoading(false); // Hide the loader
    }
  };

const hittingBackendApi = async (url, request) => {
    try {
      const response = await Post(url, request); // Assuming Post handles the API call
  
      // // Check the Content-Type header
      // const contentType = response.headers.get("content-type");
  
      if (response.ok) {
          // Handle non-JSON responses
          const text = await response.text();
          handleResponse(text)
        }
       else if (response.status === 404) {
        toast.error(`Error: ${response.statusText || "No Data is present"}`);
      } else {
        toast.error(`Error: ${response.statusText || "An error occurred"}`);
      }
    } catch (error) {
      console.error("Error:", error);
      throw new Error(`Exception: ${error.message}`);
    }
  };
  

  // Handle API response
  const handleResponse = async (response) => {
    try {
    toast.success(response);
    //   setDivState('none');
    } catch (error) {
      console.error("Failed to parse JSON:", error);
      toast.error(`Failed to handle response: ${error.message}`);
    }
  };

  // Loader component
  const Loader = require('react-loader');

  return (
    <>
      {loading && (
        <div className="loading-div">
          <Loader loaded={!loading} options={options} className="spinner" />
        </div>
      )}

      <ToastContainer />
      <Header />
      <Sidebar />
      <div id="fourtTab" className="tabcontent">
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Subscribe User</span>
          </div>
        </div>
        <div className="form-container">
          <h1>Subscribe User</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="number">Number:</label>
              <input
                type="number"
                id="number"
                className="number-input"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                required
              />
            </div>
            <div className="dropdown">
              <label htmlFor="packageType">Package Type:</label>
              <select
                id="packageType"
                className="select-element"
                value={packageType}
                onChange={(e) => setPackageType(e.target.value)}
              >
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
              </select>
              <span className="icon-d">▼</span>
            </div>
            <button type="submit" className="submit-button">
              Send
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default SendDataForm;
