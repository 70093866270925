import React from "react";
import { useNavigate } from "react-router-dom";
import '../CSS/Headercss.css';

const Header = () => {
  // To navigate to other pages
  const navigate = useNavigate();
  
  // Retrieve service name from localStorage
  const serviceName = localStorage.getItem("serviceName") || "Default Service Name"; // Fallback to a default if not found

   // Map service names to display names
   const displayName = (() => {
    switch (serviceName) {
      case "ZIM001":
        return "Yo-Number";
      case "ZIM002":
        return "YoGamezPro";
        case "ZIM003":
        return "MyKidzHub";
        case "ZIM004":
        return "MTN-ZAMBIA";
      // Add more cases as needed
      default:
        return "Default Service Name"; // Fallback display name
    }
  })();

  // Method to handle logout button
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <header className="main-header">
      <div className="top-header">
        {/* Left side - display service name */}
        <div className="service-name-container">
          <span className="service-name-display">
            {displayName}
          </span>
        </div>

        {/* Middle section - logo */}
        <div className="top-logo">
          {/* Uncomment below to include the logo */}
          {/* <img src={logo} alt="logo" /> */}
        </div>

        {/* Right side - Logout button */}
        <div className="top-header-logout">
          <button 
            className="logout-button bg-orange" 
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
