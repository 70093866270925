import React from "react";
import {Routes,Route} from "react-router-dom"
import Login from "../Pages/Login";
import Revenue from "../Pages/Revenue";
import Logs from "../Pages/Logs";
import SubUnsub from "../Pages/SubUnsub";
import SubscribeUser from '../Pages/SubscribeUser';
import SubscriptionData from "../Pages/SubscriptionData";
import UserDetails from "../Pages/UserDetails";
import Unsubscribe from "../Pages/Unsubscribe.js";

const Routing=()=>{
    return(
        <>
            {/* <BrowserRouter> */}
                <Routes>
                    <Route element={<Login/>} path="/" exact={true}></Route>
                    <Route element={<Logs/>} path="/logs" exact={true}></Route>
                    <Route element={<Revenue/>} path="/revenue" exact={true}></Route>
                    <Route element={<SubUnsub/>} path="/subUnsubData" exact={true}></Route>
                    <Route element={<SubscribeUser/>} path="/subscribe" exact={true}/>
                    <Route element={<SubscriptionData/>} path="/subscription" exact={true}/>
                    <Route element={<UserDetails />} path="/userdetails" exact={true} />
                    <Route element={<Unsubscribe />}  path="/unsubscription" exact={true} />
                </Routes>
            {/* </BrowserRouter> */}
        </>
    );
}
export default Routing;