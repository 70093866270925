import React, { useState } from "react";
import classes from "../CSS/UserDetails.module.css"; // Import your CSS file
import "../CSS/SubscribeUser.css";
import "../CSS/style.css";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { options } from "../Data/LoaderData";
import { Details } from "../Data/Api";
import Post from "../Request/Post";
import Loader from "react-loader"; // Ensure this is correctly imported

const UserDetails = () => {
  const [number, setNumber] = useState("");
  const [divState, setDivState] = useState("none");
  const [loaderState, setLoaderState] = useState(true); // Default to true to show loader initially
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  const [billingDetail, setBillingDetail] = useState([]);
  const [unsubscriptionDetail, setUnsubscriptionDetail] = useState([]);
  const [currentUnsubscriptionPage, setCurrentUnsubscriptionPage] = useState(1);
  const [currentBillingPage, setCurrentBillingPage] = useState(1);
  const itemsPerPage = 5; // Number of items per page

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoaderState(false); // Show the loader
    setDivState("block");
    let request = {
      msisdn: number,
      serviceId: localStorage.getItem("serviceName"),
    };

    try {
      await hittingBackendApi(Details, request);
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Exception: ${error.message}`);
    } finally {
      setLoaderState(true); // Hide the loader
      setDivState("none");
    }
  };

  const hittingBackendApi = async (url, request) => {
    try {
      const response = await Post(url, request);
      console.log("res",response.status)
      if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const data = await response.json();
          handleResponse(data);
        } else {
          const text = await response.text();
          return text;
        }
      } else if (response.status === 404) {
        toast.error(`Error: ${response.statusText || "No Data is present"}`);
      } else {
        toast.error(`Error: ${response.statusText || "An error occurred"}`);
      }
    } catch (error) {
      console.error("Error:", error);
      throw new Error(`Exception: ${error.message}`);
    }
  };

  const handleResponse = (response) => {
    setSubscriptionDetail(response.subscriptionTable || []);
    setUnsubscriptionDetail(response.unsubscriptionTable || []);
    setBillingDetail(response.billingTable || []);
    toast.success("Data loaded successfully");
  };

  const getPaginatedData = (data, currentPage) => {
    if (!data) return [];
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const handlePageChange = (type, direction) => {
    if (type === "unsubscription") {
      setCurrentUnsubscriptionPage((prev) => prev + direction);
    } else if (type === "billing") {
      setCurrentBillingPage((prev) => prev + direction);
    }
  };

  const convertToCSV = (data) => {
    if (!data || data.length === 0) return "";
    const headers = Object.keys(data[0]);
    const csvRows = [
      headers.join(","), // Header row
      ...data.map((row) =>
        headers
          .map((fieldName) =>
            JSON.stringify(row[fieldName], (key, value) =>
              value === null ? "" : value
            )
          )
          .join(",")
      ),
    ];
    return csvRows.join("\n");
  };

  const handleDownload = (data, fileName) => {
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="loading-div" style={{ display: divState }}>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>

      <ToastContainer />
      <Header />
      <Sidebar />
      <div id="fourtTab" className={classes.tabcontent}>
        <div className={classes["subscribers-sec"]}>
          <div className={classes["subscribers-home-l"]}>
            <span className={classes["navigation-links"]}>Home</span>
            <span>/</span>
            <span className={classes["navigation-links"]}>User Details</span>
          </div>
        </div>

        <div className={classes["form-container"]}>
          <h1>User Details</h1>
          <form className={classes.form} onSubmit={handleSubmit}>
            <div style={{ width: "100%" }}>
              <label htmlFor="number">Number:</label>
              <input
                type="number"
                id="number"
                className="number-input"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                required
              />
            </div>
            <button type="submit" className={classes["submit-button"]}>
              Send
            </button>
          </form>

          {subscriptionDetail.length > 0 && (
            <section>
              <h3>Subscription Details</h3>
              <table className={classes["data-table"]}>
                <thead>
                  <tr>
                    {Object.keys(subscriptionDetail[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {subscriptionDetail.map((item, index) => (
                    <tr key={index}>
                      {Object.entries(item).map(([key, value], idx) => (
                        <td key={idx}>
                          {key === "next_billed_date" &&
                          (!value || value.trim() === "")
                            ? "User not charged yet"
                            : value}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={classes["pagination-controls"]}>
                <button
                  onClick={() => handlePageChange("subscription", -1)}
                  disabled={currentUnsubscriptionPage === 1}
                >
                  Previous
                </button>
                <button
                  onClick={() => handlePageChange("subscription", 1)}
                  disabled={subscriptionDetail.length < itemsPerPage}
                >
                  Next
                </button>
              </div>
              <div style={{width:"100%",display:"grid",placeContent:'center',marginTop:'-20px'}}>
              <button
                onClick={() =>
                  handleDownload(subscriptionDetail, "subscriptions.csv")
                }
                className="download-button"
              >
                Download CSV
              </button>

              </div>
            </section>
          )}

          {unsubscriptionDetail.length > 0 && (
            <section style={{marginTop:"30px"}}>
              <h3>Unsubscription Details</h3>
              <table className={classes["data-table"]}>
                <thead>
                  <tr>
                    {Object.keys(unsubscriptionDetail[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {getPaginatedData(
                    unsubscriptionDetail,
                    currentUnsubscriptionPage
                  ).map((item, index) => (
                    <tr key={index}>
                      {Object.values(item).map((value, idx) => (
                        <td key={idx}>{value}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={classes["pagination-controls"]}>
                <button
                  onClick={() => handlePageChange("unsubscription", -1)}
                  disabled={currentUnsubscriptionPage === 1}
                >
                  Previous
                </button>
                <button
                  onClick={() => handlePageChange("unsubscription", 1)}
                  disabled={
                    getPaginatedData(
                      unsubscriptionDetail,
                      currentUnsubscriptionPage
                    ).length < itemsPerPage
                  }
                >
                  Next
                </button>
              </div>
              <div style={{width:"100%",display:"grid",placeContent:'center',marginTop:'-20px'}}>
              <button
                onClick={() =>
                  handleDownload(unsubscriptionDetail, "unsubscriptions.csv")
                }
                className="download-button"
              >
                Download CSV
              </button>
              </div>
            </section>
          )}

          {billingDetail.length > 0 && (
            <section style={{marginTop:"30px"}}>
              <h3>Billing Details</h3>
              <table className={classes["data-table"]}>
                <thead>
                  <tr>
                    {Object.keys(billingDetail[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {getPaginatedData(billingDetail, currentBillingPage).map(
                    (item, index) => (
                      <tr key={index}>
                        {Object.values(item).map((value, idx) => (
                          <td key={idx}>{value}</td>
                        ))}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <div className={classes["pagination-controls"]}>
                <button
                  onClick={() => handlePageChange("billing", -1)}
                  disabled={currentBillingPage === 1}
                >
                  Previous
                </button>
                <button
                  onClick={() => handlePageChange("billing", 1)}
                  disabled={
                    getPaginatedData(billingDetail, currentBillingPage).length <
                    itemsPerPage
                  }
                >
                  Next
                </button>
              </div>
              <div style={{width:"100%",display:"grid",placeContent:'center',marginTop:'-20px'}}>
              <button
                onClick={() => handleDownload(billingDetail, "billing.csv")}
                className="download-button"
              >
                Download CSV
              </button>
              </div>
            </section>
          )}
        </div>
      </div>
    </>
  );
};

export default UserDetails;

