import React from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {

  //Use to send on other route/page
  const navigate=useNavigate();

  //Method to handle navigation of sidebar
  const handleNavigate=(route)=>{
    navigate(route);
  }

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-inner">
          <p>MAIN</p>
        </div>
        {/* <!-- 1 --> */}
        {/* <button
          className="tablinks"
          onClick={()=>{
            handleNavigate("/logs");
          }}
          id="defaultOpen"
        >
          {" "}
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>Billing Logs</span>
        </button> */}
        {/* <!-- 2 --> */}
        {/* <button className="tablinks" onClick={()=>{
          handleNavigate("/revenue");
        }}>
          {" "}
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>Revenue</span>
        </button> */}
        <button className="tablinks" onClick={()=>{
          handleNavigate("/userdetails");
        }}>
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>User Details</span>
        </button>
        <button className="tablinks" onClick={()=>{
          handleNavigate("/subscribe");
        }}>
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>SubscribeUser</span>
        </button>
        {/* <button className="tablinks" onClick={()=>{
          handleNavigate("/subscription");
        }}>
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span>Subscription:</span>
        </button> */}
        <button className="tablinks" onClick={()=>{
          handleNavigate("/unsubscription");
        }}>
          <span>
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>{" "}
          <span> Unsubscription</span>
        </button>
      </div>
    </>
  );
};
export default Sidebar;