// const pre=`http://176.9.90.155:8745/authenticate/`;
// const login = `http://176.9.90.155:8745/`;
const login=``;
const pre=`authenticate/`;
// const pre=`authenticate/`;

// const pre=`http://88.99.5.236:8989/`;
// const pre = `https://www.backendreport.econetbigcash.com/`;

const loginApi=`${login}login`;
export{loginApi};

const sendLogsApi=`${pre}sendLogs`;
export{sendLogsApi};

const sendRevenueApi=`${pre}Revenue`;
export{sendRevenueApi};

const sendSubAndUnsubDataApi=`${pre}sendSubAndUnsubData`;
export{sendSubAndUnsubDataApi};

const sendWinnersApi=`${pre}sendWinners`;
export{sendWinnersApi};

const subscribeUser=`${pre}subscribeUser`;
export{subscribeUser}

const subscription = `${pre}subscription`;
export{subscription}

const Details = `${pre}details`;
export {Details}

const projectToken=`KJK@#$#JKAREZJK#$#JK`;
export{projectToken};

const Unsubscription = `${pre}unsubscription`;
export {Unsubscription}