import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { subscription } from "../Data/Api";
import Post from "../Request/Post";
import { options } from "../Data/LoaderData";
import { useNavigate } from "react-router-dom";

// Logs Page
const SubscriptionData = () => {
  // Hooks to manage state
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  // const [days, setDays] = useState(0);
  // const [type, setType] = useState("1");
  const [selectedDate, setSelectedDate] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [divState, setDivState] = useState("block");
  const [loaderState, setLoaderState] = useState(false);
  const [totalCount,setTotalCount] = useState(0);

  // Call on first load
  useEffect(() => {
    const currentDate = makeDateFormat(new Date());
    setSelectedDate(currentDate); // Set initial selected date to current date
    const request = {
      serviceId: localStorage.getItem("serviceName"),
      date: currentDate,
      size: pageSize,
      currentpage: currentPage,
    };
    hittingBackendApi(subscription, request);
  }, [pageSize]);

  // Call on page change
  useEffect(() => {
    if (selectedDate) {
      const request = {
        serviceId: localStorage.getItem("serviceName"),
        date: selectedDate,
        size: pageSize,
        currentpage: currentPage,
      };
      hittingBackendApi(subscription, request);
    }
  }, [currentPage, selectedDate, pageSize]);

  // Method to handle pagination
  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  // Handle date change
  const handleDate = (chooseDate) => {
    const date = new Date(chooseDate);
    const formattedDate = makeDateFormat(date);
    setSelectedDate(formattedDate);
    const currentDate = makeDateFormat(new Date());
    const diffTime = Math.abs(new Date(currentDate) - new Date(formattedDate));
    const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // setDays(days);
  };

  // Format date as YYYY-MM-DD
  const makeDateFormat = (date) => {
    const year = date.getFullYear();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${year}-${month}-${day}`;
  };

  // Handle search button click
  const handleSearch = () => {
    setCurrentPage(0);
    const request = {
      serviceId: localStorage.getItem("serviceName"),
      date: selectedDate,
      size: pageSize,
      currentpage: currentPage,
    };
    hittingBackendApi(subscription, request);
  };

 // Function to hit backend API
 const hittingBackendApi = async (url, request) => {
  setLoaderState(false);
  try {
    const response = await Post(url, request);
    if (response.status === 200) {
      handleResponse(response);
    } else if (response.status === "404") {
      toast.error(`Error: ${response.message || "No Data is present"}`);
    }
    else if(response.status ===401){
      navigate("/")
    }
  } catch (error) {
    toast.error(`Exception: ${error.message}`);
  } finally {
    setLoaderState(true);
  }
};
  // Handle API response
  const handleResponse = async (response) => {
    try {
      const data = await response.json();
      setData(data.data);
      setTotalCount(data.totalCount)
      setTotalPage(data.totalCount ? Math.ceil(data.totalCount / pageSize) : 0);
      setDivState("none");
    } catch (error) {
      console.error("Failed to parse JSON:", error);
      toast.error(`Failed to handle response: ${error.message}`);
    }
  };

  // Loader component
  let Loader = require("react-loader");

  return (
    <>
      <div className="loading-div" style={{ display: `${divState}` }}>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>

      <ToastContainer />
      <Header />
      <Sidebar />
      <div id="firstTab" className="tabcontent">
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Subscription Records</span>
          </div>
        </div>

        <div className="date-form">
          <span className="main-date-form ss">
            <div className="date-inner date-1-sec">
              <div className="end-date">
                <label htmlFor="start">Choose Date:</label>
                <input
                  type="date"
                  id="start"
                  name="trip-start"
                  value={selectedDate}
                  onChange={(e) => handleDate(e.target.value)}
                />
              </div>
              {/* <div className="end-date">
                <label htmlFor="start">Choose Log Type:</label>
                <select id="start" name="start" onChange={(e) => setType(e.target.value)}>
                  <option>Choose</option>
                  <option value="1">Success Billing</option>
                  <option value="988">Fail Billing</option>
                </select>
              </div> */}
              <div className="end-date">
                <label htmlFor="pageSize">Entries Per Page:</label>
                <select
                  id="pageSize"
                  name="pageSize"
                  onChange={(e) => setPageSize(parseInt(e.target.value))}
                >
                  {/* <option value="5">5</option> */}
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="date-search-btn">
                <button type="submit" onClick={handleSearch}>
                  Search
                </button>
              </div>
            </div>

            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Records:</span>
              </p>
            </div>

            <div className="main-box">
              <div className="table-sec">
                <table className="main-table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Msisdn</th>
                      <th>Amount</th>
                      <th>Sub Date</th>
                      <th>PackType</th>
                      <th>Next Billed Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((value, index) => (
                        <tr key={index}>
                          <td>{index + 1 + currentPage * pageSize}</td>
                          <td>{value.ani}</td>
                          <td>{value.default_amount}</td>
                          <td>{value.sub_date_time}</td>
                          <td>{value.pack_type}</td>
                          <td>{value.next_billed_date}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8">No Data Available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* <div className="pagination-sec">
                <div className="add-pagination">
                  <p>Showing <span>{currentPage * pageSize + 1}</span> to <span>{Math.min((currentPage + 1) * pageSize, data.length)}</span> of <span>{totalPage * pageSize}</span> entries</p>
                </div>
                <div className="pagination-next">
                  <ul className="pagination">
                    <li className="page-item">
                      <span className="page-link" 
                        onClick={() => currentPage > 0 && handlePagination(currentPage - 1)}>
                        Previous
                      </span>
                    </li>
                    <li className="page-item">
                      <span className="page-link"
                        onClick={() => currentPage < totalPage - 1 && handlePagination(currentPage + 1)}>
                        Next
                      </span>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="pagination-sec">
                <div className="add-pagination">
                  <p>
                    Showing
                    <span>{currentPage * pageSize + 1}</span> to
                    <span>
                      {Math.min((currentPage + 1) * pageSize, totalCount)}
                    </span>{" "}
                    of
                    <span>{totalCount}</span> entries
                  </p>
                </div>
                <div className="pagination-next">
                  <ul className="pagination">
                    <li className="page-item">
                      <span
                        className="page-link"
                        onClick={() =>
                          currentPage > 0 && handlePagination(currentPage - 1)
                        }
                      >
                        Previous
                      </span>
                    </li>
                    <li className="page-item">
                      <span
                        className="page-link"
                        onClick={() =>
                          currentPage < totalPage - 1 &&
                          handlePagination(currentPage + 1)
                        }
                      >
                        Next
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};

export default SubscriptionData;
