// export default Revenue;
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { sendRevenueApi } from "../Data/Api";
import Post from "../Request/Post";
import { options } from "../Data/LoaderData";

const Revenue = () => {
  // Hook to store selected date
  const [selectedDate, setSelectedDate] = useState("");

  // Hook to store data as an array of objects
  const [data, setData] = useState([]);

  // to load on start
  useEffect(() => {
    let dateToSend = selectedDate || makeDateFormat(new Date());
    let request = {
      date: dateToSend,
      serviceId: localStorage.getItem("serviceName"),
    };
    hitOnBackend(sendRevenueApi, request);
    // eslint-disable-next-line
  }, []);

  // Method to handle date change
  const handleDate = (chooseDate) => {
    setSelectedDate(chooseDate);
    console.log("chosenDate ", chooseDate);
  };

  // Method to handle search button
  const handleSearchButton = () => {
    let dateToSend = selectedDate || makeDateFormat(new Date());
    let request = {
      date: dateToSend,
      serviceId: localStorage.getItem("serviceName"),
    };
    hitOnBackend(sendRevenueApi, request);
    setDivState("block");
  };

  // Method to hit on backend
  const hitOnBackend = (url, request) => {
    let promise = Post(url, request);
    promise.then((e) => {
      handleResponse(e);
    });
  };

  // Method to handle response
  const handleResponse = async (e) => {
    const data = await e.json();
    // console.log("e", data);

    // Assuming 'e.data' contains the array of records
    setData(data || []); // Set the response data or an empty array if not present
    setDivState("none");
  };

  // Method to make dates in format
  const makeDateFormat = (date) => {
    let year = date.getFullYear();
    let month =
      date.getMonth() + 1 < 10
        ? "0".concat(date.getMonth() + 1)
        : date.getMonth() + 1;
    let day = date.getDate() < 10 ? "0".concat(date.getDate()) : date.getDate();
    return `${year}-${month}-${day}`;
  };

  // Loader Stuff
  let Loader = require("react-loader");

  // Hook to store starting div state (loading div)
  const [divState, setDivState] = useState("block");

  // State for Loader
  // eslint-disable-next-line
  const [loaderState, setLoaderState] = useState(false);

  return (
    <>
      <div className="loading-div" style={{ display: `${divState}` }}>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>

      {/* <!-- sec2 --> */}
      <ToastContainer />
      <Header />
      <Sidebar />
      <div id="secondTab" className="tabcontent">
        {/* <!-- top-home --> */}
        <div className="subscribers-sec">
          <div className="subscribers-home-l">
            <span className="navigation-links">Home</span>
            <span>/</span>
            <span className="navigation-links">Revenue</span>
          </div>
        </div>

        {/* <!-- date --> */}
        <div className="date-form">
          <span className="main-date-form">
            {/* <!-- user --> */}
            <div className="title-ic">
              <p>
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>{" "}
                <span>Revenue</span>
              </p>
            </div>

            {/* <!-- date --> */}
            <div className="date-inner">
              <div className="end-date">
                <label htmlFor="start">Choose Date:</label>
                <input
                  type="date"
                  id="start"
                  name="trip-start"
                  onChange={(e) => {
                    handleDate(e.target.value);
                  }}
                />
              </div>
              <div className="date-search-btn">
                <button
                  className="bg-orange"
                  type="submit"
                  onClick={() => {
                    handleSearchButton();
                  }}
                >
                  Search
                </button>
              </div>
            </div>

            {/* <!-- select box --> */}
            <div className="main-box">
              {/* <!-- table --> */}
              <div className="table-sec">
                <table className="main-table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Date</th>
                      <th>Pack</th>
                      <th>Renewal Count</th>
                      <th>New Subscription</th>
                      <th>Renewals Revnue</th>
                      <th>Subscription Revenue</th>
                      <th>Total Revenue</th>
                      <th>unsubscription</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.RequestDate}</td>
                        <td>{item.pack_type}</td>
                        <td>{item.count_ren}</td>
                        <td>{item.count_sub}</td>
                        <td>{item.sum_ren}</td>
                        <td>{item.sum_sub}</td>
                        <td>{item.sum_sub_and_ren}</td>
                        <td>{item.unsub_count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};

export default Revenue;
